<template>
  <div>
    <el-card>
      <div class="flexRow" style="margin-bottom:10px">
        <div class="flexRow" style="margin-right:10px">
          <div class="keyFont">关键字：</div>
          <el-input v-model="keywords" style="width:250px" placeholder="视频描述、商品名称"></el-input>
        </div>
        <el-button type="primary" style="margin-right:10px" @click="query">查询</el-button>
        <buttonPermissions :datas="'shortvideopush'">
          <el-button type="primary" style="margin-right:10px" @click="todetail({Id:0})">添加</el-button>
        </buttonPermissions>
        <buttonPermissions :datas="'shortvideoset'">
          <el-button type="text" style="margin-right:10px" @click="setlimit">限领设置</el-button>
        </buttonPermissions>
      </div>
      <div class="flexRow" style="margin-bottom:10px">
        <div class="defaultfont">账户剩余视频点数：
          <span style="color:#f56c6c">{{pagemsg.VideoPoint}}点</span>
        </div>
      </div>
      <el-table :data="tablelist" v-loading="tableloading">
        <el-table-column prop="" label="视频信息">
          <template slot-scope="scope">
            <div class="flexRow">
              <div class="imgbox">
                <img :src="scope.row.CoverImgUrlComplete" alt="">
              </div>
              <div class="ellipsis-three">{{scope.row.Describe}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="带货商品">
          <template slot-scope="scope">
            <div>
              <div class="ellipsis">{{scope.row.Name}}</div>
              <div style="color:#999999;font-size:12px">{{scope.row.ProductNo}}</div>
              <div style="color:#f56c6c;font-size:14px">￥{{scope.row.ProductPrice}}
                <span v-if="scope.row.ProductMaxPrice>scope.row.ProductPrice">~￥{{scope.row.ProductMaxPrice}}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="AddTime" label="达人佣金" width="100">
          <template slot-scope="scope">
            {{scope.row.TalentCommissionRate||0}}%
          </template>
        </el-table-column>
        <el-table-column prop="AddTime" label="创建时间" width="200"></el-table-column>
        <el-table-column prop="" label="操作" width="150">
          <template slot-scope="scope">
            <div class="flexRow">
              <buttonPermissions :datas="'shortvideoedit'">
                <el-button type="text" style="margin-right:10px" @click="todetail(scope.row)">编辑</el-button>
              </buttonPermissions>
              <buttonPermissions :datas="'shortvideodel'">
                <el-button type="text" style="color:#f56c6c;margin-right:10px" @click="del(scope.row)">删除</el-button>
              </buttonPermissions>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px;text-align:right" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :visible.sync="limitShow" title="限领设置" v-loading="limitLoading" width="500px" @close="closelimit" class="dialog-body-paddingTop-10">
      <div class="flex-start">
        <div>单日限领数量：</div>
        <div>
          <div>
            <el-radio v-model="limitmsg.limittype" :label="0">不限制领取数量</el-radio>
          </div>
          <div class="flexRow" style="margin:20px 0px 0px 0px">
            <el-radio v-model="limitmsg.limittype" :label="1" style="margin-right:0px">限制领取</el-radio>
            <el-input v-model="limitmsg.limitnum" style="width:100px;margin:0px 10px" :disabled="limitmsg.limittype!=1"
               onkeyup="value=value.replace(/[^0-9]/g,'')"></el-input>
            <span>个/人</span>
          </div>
          <div style="color:#999999;font-size:12px;margin-top:5px">推手单日最多可领取的短视频数量</div>
        </div>
      </div>
      <div style="margin-top:30px;text-align:right">
        <el-button @click="closelimit">取消</el-button>
        <el-button type="primary" @click="confirmlimit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/config"
import buttonPermissions from '@/components/buttonPermissions';
import {
  shortVideoshortVideoPageList,
  shortVideoshortVideoDelete,
  shortVideovideoPointReceiveLimitSave,
  shortVideovideoPointReceiveLimitInfo
} from "@/api/sv3.0.0"
export default {
  components: {
    buttonPermissions
  },
  data () {
    return {
      pagemsg:{},
      keywords:'',
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,

      limitShow:false,
      limitLoading:false,
      limitmsg:{
        limittype:1,
        limitnum:''
      }
    }
  },
  mounted () {
    this.gettablelist()
  },
  methods: {
    todetail(row){
      this.$router.push({
        path:'/market/shortvideo/detail',
        query:{
          Id:row.Id
        }
      })
    },
    del(row){
      this.$confirm('是否确认删除短视频？删除后不可恢复。该操作不影响已经领取的视频。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.confirmdel(row)
      }).catch(() => {});
    },
    async confirmdel(e){
      try{
        this.tableloading = true
        let row = await e
        let res = await shortVideoshortVideoDelete({
          Id:row.Id
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.gettablelist()
        }
      }finally{
        this.tableloading = false
      }
    },
    async confirmlimit(){
      let limit = JSON.parse(JSON.stringify(this.limitmsg))
      if(limit.limittype==1&&(!limit.limitnum||limit.limitnum<1||limit.limitnum>999||limit.limitnum!=parseInt(limit.limitnum))){
        this.$message.error('数量请输入在1~999之间，整数')
        return
      }
      try{
        this.limitLoading = true
        let data = limit
        data.VideoPointReceiveLimit = data.limittype==1?data.limitnum:0
        let res = await shortVideovideoPointReceiveLimitSave(data)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.closelimit()
        }
      }finally{
        this.limitLoading = false
      }
    },
    closelimit(){
      this.limitShow = false
      this.limitmsg = {
        limittype:1,
        limitnum:''
      }
    },
    setlimit(){
      this.limitShow = true
      this.getlimit()
    },
    async getlimit(){
      try{
        this.limitLoading = true
        let res = await shortVideovideoPointReceiveLimitInfo()
        if(res.IsSuccess){
          // console.log(res)
          this.limitmsg = {
            limittype:res.Result.VideoPointReceiveLimit==0?0:1,
            limitnum:res.Result.VideoPointReceiveLimit==0?'':res.Result.VideoPointReceiveLimit,
          }
        }
      }finally{
        this.limitLoading = false
      }
    },
    query(){
      this.currentPage = 1
      this.gettablelist()
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Keywords:this.keywords,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        }
        let res = await shortVideoshortVideoPageList(data)
        if(res.IsSuccess){
          this.pagemsg = res.Result
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    }
  }
}
</script>

<style lang = "less" scoped>
  .imgbox{
    overflow: hidden;
    margin-right:5px;
    width: 68px;
    height: 78px;
    flex-shrink: 0;
    img{
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100px;
    }
  }
  .flexRow{
    display: flex;
    align-items: center;
  }
  .keyFont{
    font-size: 14px;
    font-weight: bold;
    color: #606266;
    white-space: nowrap;
    flex-shrink: 0;
  }
  .defaultfont{
    font-size: 14px;
    line-height: 1.5;
    color: #606266;
  }
  .flex-start{
    display: flex;
    align-items: flex-start;
  }
</style>